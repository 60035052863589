/*------------------------------------------------------------------[MAIN STYLESHEET]PROJECT:	Roxo HTMLVERSION:	1.0.0-------------------------------------------------------------------*/@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700|Montserrat:300,400,500,600,700&display=swap");body{font-family:"Josefin Sans",sans-serif;font-size:16px;color:#9d9ea5}
h1,h2,h3,h4,h5,h6{font-family:"Montserrat",sans-serif;font-weight:600;color:#00113e}
p{font-size:16px;color:#9d9ea5}h1{font-size:60px}h2{font-size:50px}h3{font-size:30px}
h4{font-size:25px}h5{font-size:18px}h6{font-size:15px;font-weight:300}.btn{font-family:"Montserrat",sans-serif;border:0;color:#fff !important;padding:20px 45px;border-radius:0}
.btn:focus{box-shadow:none}@media(max-width:991px){.btn{padding:17px 40px}}.btn .btn-area{display:block;overflow:hidden}
.btn .btn-area span{position:relative;display:block;padding:2px 0;transition:transform .9s cubic-bezier(0.19,1,0.22,1);transform-origin:left center}
.btn .btn-area span::after{content:attr(data-text);position:absolute;top:150%;left:0;transform:skewY(30deg);transform-origin:left center;transition:transform .9s cubic-bezier(0.19,1,0.22,1)}
.btn:hover .btn-area span{transform:translateY(-150%) skewY(7deg)}.btn:hover .btn-area span::after{transform:skewY(-7deg)}
.btn:visited .btn-area span{transform:translateY(-150%) skewY(7deg)}.btn:visited .btn-area span::after{transform:skewY(-7deg)}
.btn-sm{padding:15px 40px !important}.btn-sm-rounded{border-radius:40px}.btn-primary{background:#eaa4a4;color:#fff}
.btn-primary:hover{background:#e07b7b}.btn-secondary{background:#add6d2;color:#fff}
.btn-secondary:hover{background:#8bc5bf}body{background:#fff}.section-title{margin-bottom:70px}
@media(max-width:991px){.section-title{text-align:center}}.section-title h2{font-weight:300;letter-spacing:2px;text-transform:uppercase;margin-bottom:15px}
@media(max-width:991px){.section-title h2{font-size:40px}}.section-title p{font-size:20px;font-weight:300}
.form-control{background:transparent;border-radius:0;margin-bottom:30px;padding:0 0 10px 0;border-width:0 0 1px 0;border-color:#eaa4a4;font-weight:300;letter-spacing:1px;-webkit-appearance:none;height:42px}
.form-control:focus{box-shadow:none;background:transparent;border-color:#9d9ea5}select.form-control{height:42px !important;position:relative}
.select-wrapper{position:relative}.select-wrapper::before{content:"";position:absolute;width:16px;height:10px;background:url("../images/angle-down-select.svg");background-size:contain;background-repeat:no-repeat;right:15px;top:50%;transform:translateY(-80%)}
textarea.form-control{height:auto}.site-navigation{padding:20px 0;background:#fff;position:fixed;top:0;width:100%;z-index:1000;transition:all .3s cubic-bezier(0.55,0.085,0.68,0.53)}
@media(max-width:991px){.site-navigation{background:#fff;margin:10px;border-radius:5px;top:0;width:calc(100% - 20px);box-shadow:0 0 12px 0 rgba(0,0,0,0.09)}
}@media(max-width:575px){.site-navigation{padding:15px}}.site-navigation .navbar-brand{padding:0;margin:0}
.site-navigation .navbar-brand img{max-width:78px}@media(max-width:991px){.site-navigation .nav-item{text-align:center}
}.site-navigation .nav-item .nav-link{font-family:"Montserrat",sans-serif;color:#00113e;transition:all .3s ease;padding:15px 18px;position:relative}
.site-navigation .nav-item .nav-link:hover{color:#eaa4a4}.site-navigation .nav-item:last-child{margin-left:15px}
.site-navigation .navbar-toggler{outline:0;padding:0}.site-navigation .navbar-toggler .icon-bar{height:2px;width:25px;transition:all .2s;background:#eaa4a4;display:block}
.site-navigation .navbar-toggler .icon-bar:not(:last-child){margin-bottom:5px}.site-navigation .navbar-toggler .icon-bar:nth-child(1){transform:rotate(45deg);transform-origin:10% 10%}
.site-navigation .navbar-toggler .icon-bar:nth-child(2){opacity:0;filter:alpha(opacity=0)}
.site-navigation .navbar-toggler .icon-bar:nth-child(3){transform:rotate(-45deg);transform-origin:10% 90%}
.site-navigation .navbar-toggler.collapsed .icon-bar:nth-child(1){transform:rotate(0)}
.site-navigation .navbar-toggler.collapsed .icon-bar:nth-child(2){opacity:1;filter:alpha(opacity=1)}
.site-navigation .navbar-toggler.collapsed .icon-bar:nth-child(3){transform:rotate(0)}
.site-navigation.nav-bg{padding:12px 0;box-shadow:0 0 12px 0 rgba(0,0,0,0.09)}@media(max-width:575px){.site-navigation.nav-bg{padding:15px}
}.site-hero{position:relative;height:100vh;background-image:url("../images/backgrounds/hero-background.jpg");background-size:cover;background-position:center center;display:flex;align-items:center;justify-content:center}
@media(max-width:991px){.site-hero{height:auto;padding:200px 0}}@media(max-width:575px){.site-hero{padding:150px 0}
}.site-hero-content h1{margin-bottom:75px}@media(max-width:991px){.site-hero-content h1{font-size:30px}
}.site-hero-content-buttons{margin:0;padding:0}.site-hero-content-buttons li{display:inline-block}
@media(max-width:575px){.site-hero-content-buttons li{display:block}}.site-hero-content-buttons li:not(:last-child){margin-right:30px}
@media(max-width:575px){.site-hero-content-buttons li:not(:last-child){margin-right:0;margin-bottom:20px}
}.site-hero-content-buttons li .btn{font-weight:600}@media(max-width:575px){.site-hero-content-buttons li .btn{width:90%;margin:0 auto;display:block}
}.site-hero-scroll{position:absolute;left:50%;bottom:70px;transform:translateX(-50%)}
.site-counter{padding:85px 0;color:#fff;background:#add6d2}.site-counter .section-title{margin-bottom:40px}
@media(max-width:575px){.site-counter{padding:50px 0}}@media(max-width:991px){.site-counter-item{margin-bottom:30px;text-align:center}
}.site-counter-item-title{font-size:20px;font-weight:300}.site-counter-item-number{color:#fff;font-size:60px;margin-bottom:0;font-weight:700}
.site-project,.site-blog{padding:120px 0;overflow:hidden}.site-project-item,.site-blog-post{position:relative;margin-bottom:30px;transition:all .3s ease-in}
.site-project-item-thumb img,.site-blog-post-thumb img{width:100%}.site-project-item-content,.site-blog-post-content{position:absolute;background:#f7f9ff;width:100%;padding:45px 50px;bottom:0;transition:all .3s cubic-bezier(0.36,0.03,0,0.91)}
@media(max-width:767px){.site-project-item-content,.site-blog-post-content{padding:30px;padding-bottom:75px}
}.site-project-item-content span,.site-blog-post-content span{font-family:"Montserrat",sans-serif;color:#eaa4a4;display:block;margin-bottom:10px}
.site-project-item-content h3,.site-blog-post-content h3{margin-bottom:0}.site-project-item-content a.read-more,.site-blog-post-content a.read-more{position:absolute;bottom:40px;display:block;width:100%;color:#9d9ea5;opacity:0;transform:translateY(20px);transition:all .3s cubic-bezier(0.36,0.03,0,0.91)}
@media(max-width:767px){.site-project-item-content a.read-more,.site-blog-post-content a.read-more{transform:translateY(0);opacity:1}
}.site-project-item:hover,.site-blog-post:hover{box-shadow:0 30px 45px 0 rgba(0,0,0,0.01),0 30px 45px 0 rgba(0,0,0,0.02),0 30px 45px 0 rgba(0,0,0,0.03),0 30px 45px 0 rgba(0,0,0,0.04)}
.site-project-item:hover .site-project-item-content,.site-project-item:hover .site-blog-post-content,.site-blog-post:hover .site-project-item-content,.site-blog-post:hover .site-blog-post-content{padding-bottom:75px}
.site-project-item:hover .site-project-item-content a,.site-project-item:hover .site-blog-post-content a,.site-blog-post:hover .site-project-item-content a,.site-blog-post:hover .site-blog-post-content a{transform:translateY(0);opacity:1}
.site-project-cta{margin-top:30px;display:inline-block;position:relative;font-family:"Montserrat",sans-serif;font-size:18px;font-weight:500;color:#eaa4a4}
.site-project-cta::before{content:"";position:absolute;height:2px;width:100%;background:#eaa4a4;bottom:-2px;transition:transform .3s cubic-bezier(0.36,0.03,0,0.91);transform-origin:right;transform:scaleX(0)}
.site-project-cta:hover{color:#eaa4a4;text-decoration:none}.site-project-cta:hover::before{transform:scaleX(1);transform-origin:left}
.site-project-header{padding:170px 0 100px;background:#f7f9ff}@media(max-width:767px){.site-project-header{padding:120px 0 80px}
}.site-project-header-content h1{font-size:55px}.site-project-header-content h1 span{color:#eaa4a4}
@media(max-width:767px){.site-project-header-content h1{font-size:40px}}@media(max-width:991px){.site-project-header-content{text-align:center;margin-bottom:30px}
}.site-project-header-action{display:block;text-align:center}.site-project-header-action span{display:block;font-size:30px;font-weight:300;color:#00113e;margin-bottom:20px}
@media(max-width:767px){.site-project-header-action span{font-size:25px}}.site-project-header-action img{width:66px}
@media(max-width:767px){.site-project-header-action img{width:40px}}.site-project-header-action:hover{text-decoration:none}
.site-project-pagination,.site-blog-pagination{margin-top:40px}.site-project-pagination .pagination .page-item:not(:last-child),.site-blog-pagination .pagination .page-item:not(:last-child){margin-right:10px}
.site-project-pagination .pagination .page-item .page-link,.site-blog-pagination .pagination .page-item .page-link{font-size:24px;border-radius:0;border:0;padding:12px 20px;color:#00113e;font-weight:300;transition:all .3s ease}
.site-project-pagination .pagination .page-item.active .page-link,.site-blog-pagination .pagination .page-item.active .page-link{background-color:#eaa4a4;color:#fff}
.site-project-single-section{padding:250px 0 100px}@media(max-width:767px){.site-project-single-section{padding:100px 0}
}.site-project-single h1{font-size:40px;font-weight:500;margin-bottom:40px}.site-project-single-image{display:flex;flex-wrap:wrap;margin-bottom:30px}
.site-project-single-image img{width:48%}.site-project-single-image img:nth-child(2n){margin-left:auto}
@media(max-width:767px){.site-project-single-image img{width:100%}}.site-project-single-description{margin-bottom:50px}
.site-project-single-description p{margin-bottom:30px}.site-project-single-action a{position:relative;font-size:24px;font-weight:300;color:#00113e;display:flex;margin-left:auto;justify-content:flex-end}
@media(max-width:575px){.site-project-single-action a{transform:translateX(-10px)}
}.site-project-single-action a .link-area{display:inline-block;overflow:hidden}.site-project-single-action a .link-area span{position:relative;display:block;padding:2px 0;transition:transform .9s cubic-bezier(0.19,1,0.22,1);transform-origin:left center}
.site-project-single-action a .link-area span::after{content:attr(data-text);position:absolute;top:150%;left:0;transform:skewY(30deg);transform-origin:left center;transition:transform .9s cubic-bezier(0.19,1,0.22,1)}
.site-project-single-action a:hover{text-decoration:none}.site-project-single-action a:hover .link-area span{transform:translateY(-150%) skewY(7deg)}
.site-project-single-action a:hover .link-area span::after{transform:skewY(-7deg)}
.site-project-single-action img{filter:invert(1);transform:rotate(90deg);margin-left:30px}
@media(max-width:575px){.site-blog{padding:70px 0}}.site-blog.details{padding:150px 0 80px}
@media(max-width:767px){.site-blog.details{padding:100px 0 60px}}.site-blog-header{padding:150px 0 0}
@media(max-width:575px){.site-blog-header{padding:100px 0 0}}.site-blog-header h1{font-weight:700;text-transform:uppercase;letter-spacing:10px;margin-bottom:20px}
@media(max-width:575px){.site-blog-header h1{font-size:35px}}.site-blog-header p{line-height:28px;margin-bottom:0}
@media(max-width:575px){.site-blog-post-content{padding:15px 25px 40px}}.site-blog-post-content span{letter-spacing:1px}
.site-blog-post-content h3{margin-bottom:15px}.site-blog-post-content h3 a{letter-spacing:0;color:#00113e;transition:.3s ease}
.site-blog-post-content h3 a:hover{color:#eaa4a4;text-decoration:none}@media(max-width:575px){.site-blog-post-content h3{font-size:20px}
}.site-blog-post-content a{color:#eaa4a4;letter-spacing:2px}@media(max-width:575px){.site-blog-post-content a{transform:translateY(22px)}
}.site-blog-details img{max-width:100%;margin-bottom:20px}.site-blog-details .blog-title{font-size:40px}
.site-blog-details .feature-image{margin:20px 0 30px}.site-blog-details h2,.site-blog-details h3,.site-blog-details h4,.site-blog-details h5,.site-blog-details h6{margin-bottom:15px}
.site-blog-details blockquote{color:#00113e;font-size:18px}.site-blog-details cite{margin-top:10px;display:block}
.site-testimonial{background:#f7f9ff;padding:100px 0;overflow:hidden}.site-testimonial-item{background:#fff;padding:30px;margin-bottom:30px;transition:all .3s ease-in}
.site-testimonial-item.active{box-shadow:0 30px 45px 0 rgba(0,0,0,0.05)}.site-testimonial-item.inactive{opacity:.4}
.site-testimonial-item-header{display:flex;align-items:center;margin-bottom:20px}
.site-testimonial-item-header .thumb{width:80px;margin-right:15px}.site-testimonial-item-header .thumb img{width:100%}
.site-testimonial-item-header .person h5{margin-bottom:5px}.site-testimonial-item-header .person p{margin-bottom:0;color:#646569}
.site-testimonial-item-body{margin-bottom:0;font-weight:300}.site-cta{padding:130px 0;background-image:url("../images/backgrounds/cta-background.jpg");background-size:cover;background-position:center center}
@media(max-width:991px){.site-cta{padding:100px 0}}.site-cta-title{font-size:70px;font-weight:700;letter-spacing:10px;margin-bottom:60px}
@media(max-width:991px){.site-cta-title{letter-spacing:5px}}@media(max-width:1200px){.site-cta-title{font-size:50px;margin-bottom:40px}
}@media(max-width:575px){.site-cta-title{font-size:40px;letter-spacing:1}}.site-cta-buttons{margin:0;padding:0}
.site-cta-buttons li{display:inline-block}@media(max-width:575px){.site-cta-buttons li{display:block}
}.site-cta-buttons li:not(:last-child){margin-right:40px}@media(max-width:575px){.site-cta-buttons li:not(:last-child){margin-right:0;margin-bottom:20px}
}@media(max-width:575px){.site-cta-buttons li .btn{display:block;width:90%;margin:0 auto}
}.site-about-header{padding-top:80px;background-color:#f7f9ff}@media(max-width:575px){.site-about-header{padding-top:60px;text-align:center}
}.site-about-wrapper{display:flex;align-items:center;flex-wrap:wrap}.site-about-company{background:#eaa4a4;width:40%;padding:70px 38px}
@media(max-width:991px){.site-about-company{width:100%;margin-bottom:20px}}.site-about-company h1{color:#fff;font-weight:400;font-size:25px;line-height:60px;text-transform:uppercase;letter-spacing:1px}
.site-about-description{width:60%;padding-left:50px}@media(max-width:991px){.site-about-description{width:100%;padding:0 38px}
}.site-about-description p{font-size:25px;font-weight:300;margin-bottom:0}.site-expertise{padding:100px 0;background:#f7f9ff}
.site-expertise-list{padding:0;margin:10px 0 0 20px}@media(max-width:575px){.site-expertise-list{text-align:center;margin:0}
}.site-expertise-list li{list-style:none;font-size:25px;font-weight:300;color:#00113e}
.site-expertise-list li:not(:last-child){margin-bottom:10px}.site-client{padding:100px 0 50px}
.site-client .section-title{margin-bottom:100px}.site-client-wrapper{display:flex;flex-wrap:wrap}
@media(max-width:991px){.site-client-wrapper{justify-content:center}}.site-client-item{text-align:center;width:20%;margin-bottom:50px}
@media(max-width:991px){.site-client-item{width:33.33%}}@media(max-width:575px){.site-client-item{width:50%}
}.site-team{padding:100px 0}.site-team-member{background:#f7f9ff;padding:65px 30px 30px;margin:50px 0 30px;position:relative}
@media(max-width:767px){.site-team-member{text-align:center}}.site-team-member-image{position:absolute;width:100px;height:100px;border-radius:50%;overflow:hidden;left:30px;top:0;transform:translateY(-50%)}
@media(max-width:767px){.site-team-member-image{left:50%;transform:translate(-50%,-50%)}
}.site-team-member-content h3{font-size:20px;color:#eaa4a4;margin-bottom:10px;font-weight:500}
.site-team-member-content p{font-size:18px;color:#00113e;margin-bottom:20px;font-weight:300}
.site-team-member-social{margin:0;padding:0}.site-team-member-social li{display:inline-block}
.site-team-member-social li a{color:#00113e}.site-team-member-social li:not(:last-child){margin-right:10px}
.site-contact{padding:150px 0 100px;background:#f7f9ff}@media(max-width:575px){.site-contact{padding:120px 0 100px}
}.site-contact-title{font-weight:700}@media(max-width:767px){.site-contact-title{font-size:40px}
}.site-contact-title span{color:#eaa4a4}.site-contact-form{margin-top:20px}.site-contact-form h4{font-weight:300;text-transform:uppercase;margin-bottom:50px;letter-spacing:3px}
@media(max-width:767px){.site-contact-form h4{font-size:20px}}@media(max-width:991px){.site-contact-form h4{margin-bottom:70px}
}.contact-form-generator{margin-top:20px}.contact-form-generator a{color:#00113e;font-size:13px;text-transform:uppercase;margin-bottom:50px;letter-spacing:3px}
.contact-form-generator a:hover{text-decoration:none}@media(max-width:767px){.contact-form-generator a{font-size:20px}
}@media(max-width:991px){.contact-form-generator a{margin-bottom:70px}}.site-footer{background:#add6d2;padding:100px 0}
.site-footer-logo{margin-bottom:50px}@media(max-width:575px){.site-footer-logo{text-align:center}
}@media(max-width:991px){.site-footer-widget{margin-bottom:40px}}@media(max-width:575px){.site-footer-widget{text-align:center}
}.site-footer-widget-title{color:#fff;margin-bottom:30px}@media(max-width:991px){.site-footer-widget-title{margin-bottom:20px}
}.site-footer-widget-description{color:#fff;line-height:30px}.site-footer-widget-description a{color:#fff}
.site-footer-widget-links{margin:0;padding:0}.site-footer-widget-links li{list-style:none}
.site-footer-widget-links li a{color:#fff;transition:all .3s cubic-bezier(0.36,0.03,0,0.91);display:block}
.site-footer-widget-links li:not(:last-child) a{padding-bottom:9px}@media(max-width:575px){.site-footer-widget-top{text-align:center;display:block}
}.site-footer-widget-top img{margin-bottom:20px}.site-footer-widget-top p{color:#fff}
.site-footer-widget-top:hover{text-decoration:none}.site-footer-copyright p{color:#fff;margin:50px auto 0;text-align:center}
.site-footer-copyright a{color:#00113e;transition:all .3s ease}@media(max-width:767px){.site-footer-copyright a{font-size:20px}
}@media(max-width:991px){.site-footer-copyright a{margin-bottom:70px}}