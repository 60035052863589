/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Roxo HTML
VERSION:	1.0.0
-------------------------------------------------------------------*/

@import "variables";

@import "typography";

@import "mixins";

@import "buttons";

@import "common";

@import "components/navigation";

@import "components/hero";

@import "components/counter";

@import "components/project";

@import "components/blog";

@import "components/testimonial";

@import "components/cta";

@import "components/about";

@import "components/expertise";

@import "components/client";

@import "components/team";

@import "components/contact";

@import "components/footer";